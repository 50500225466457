import * as htmlToImage from 'html-to-image'
import { Controller } from "@hotwired/stimulus"

Stimulus.register("render-image", class extends Controller {
  static values = {
    filename: String, 
    source: String
  };

  save(event) {
    var sourceNode = document.querySelector(this.sourceValue);
    const options = {
      backgroundColor: '#222222',
    }
    document.querySelector('body').style.overflow="hidden"
    sourceNode.classList.remove('hidden')
    setTimeout(() => {
      htmlToImage.toPng(sourceNode, options)
        .then(dataUrl => {
          var link = document.createElement('a');
          link.download = `${this.filenameValue}.png`;
          link.href = dataUrl;
          link.click();
          sourceNode.classList.add('hidden');
          document.querySelector('body').style.removeProperty("overflow");
        })
    })
  }
})

